@tailwind base;
@tailwind components;
@tailwind utilities;
@import 'tippy.js/dist/tippy.css';

html {
  @apply bg-gradient-to-tr from-stone-200 via-slate-200 h-full;
}
body {
  @apply max-w-6xl h-full mx-auto;
}
div.profile-frame {
  width: 200px;
  height: 200px;
  margin: 1em auto;
  border-radius: 8rem;
  background-image: url("../../images/david-pic.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.vert-text {
  @apply px-1 py-2;
  writing-mode: vertical-rl;
  text-orientation: upright;
}
div.hort-text {
  @apply px-8;
  writing-mode: unset;
  text-orientation: unset;
  transition: all 0.25s;
}
div.menu-rollout {
  @apply w-full;
  transition: all 1s;
}
div.menu-rollin {
  @apply w-0;
  transition: all 0.5s;
}
div.gallery-rollout {
  @apply flex gap-2 w-full;
  transition: all 2s;
}
div.gallery-rollout > section {
  @apply min-w-xl;
  transition: all 1s;
}
div.gallery-rollin > section {
  @apply min-w-0 w-0;
}
div.gallery-rollin section * {
  @apply hidden;
}
section.project-bar-perspective {
}
section.project-bar-perspective div {
  border-radius: 0.5rem;
  transform: perspective(60px) rotateY(4deg) rotateX(0.4deg);
  box-shadow: -6px 6px 3px 1px rgba(0, 0, 0, 0.3);
}
section.project-header-perspective div {
  border-radius: 0.5rem;
  transform: perspective(0px) rotateX(0deg);
  box-shadow: -6px 6px 3px 1px rgba(0, 0, 0, 0.3);
}
div.project-rollout {
  transform: translateY(0);
  transition: all 1.5s linear;
}
div.project-rollin {
  transform: translateY(-100%);
  transition: all 1.5s ease;
}
